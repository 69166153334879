import React from 'react';
import styled from 'styled-components';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface ErrorMessageProps {
  error?: string[] | string | undefined | void;
  spaceleft?: boolean;
}

const ErrorContainer = styled.div<{ spaceleft?: boolean }>`
  display: flex;
  align-items: center;
  color: #ff0000;
  font-size: 14px;
  margin: var(--spacing-sm) 0;
  margin-left: ${({ spaceleft }) => (spaceleft ? 'var(--spacing-md)' : '0')};

  & .icon {
    margin-right: 5px;
  }
`;

const ErrorMessage = ({ error, spaceleft }: ErrorMessageProps) => {
  if (!error || !error.length) return null;

  return (
    <ErrorContainer className="alert alert-danger is-sm" spaceleft={spaceleft}>
      <WarningAmberIcon /> &nbsp; {Array.isArray(error) ? error[0] : error}
    </ErrorContainer>
  );
};

export default ErrorMessage;
