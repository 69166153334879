import { RatingAspect } from '../review';

export enum QuestionType {
  CHECKBOX = 'checkbox',
  MULTIPLE_CHOICE = 'multiple-choice',
  TEXT = 'text',
  SHORT_TEXT = 'short-text',
  DATE = 'date',
  NUMBER = 'number',
  RATING = 'rating',
  TAG_INPUT = 'tag-input',
}

export enum QuestionValidationRules {
  REQUIRED = 'required',
  MINIMUM_CHARACTERS = 'minimum-characters',
}

export interface Question {
  name: string;
  type: QuestionType;
  question: string;
  validationRules?: Array<QuestionValidationRules>;
  required?: boolean;
  conditionallyShow?: boolean;
  conditionalShowRules?: Array<ConditionalShowRules>;
}

export type ConditionalShowRules = {
  field: string;
  value: string;
  notValues?: string[];
  compareTo?: string;
  comparison?: 'lessThan' | 'greaterThan' | 'equalTo';
};

export interface MultipleChoiceQuestion extends Question {
  type: QuestionType.MULTIPLE_CHOICE;
  options: string[];
}

export interface RatingQuestion extends Question {
  label: string;
  name: string;
  shortLabel: string;
  responseName: RatingAspect;
}

export interface CheckboxQuestion extends Question {
  type: QuestionType.CHECKBOX;
  options: string[];
}
export interface TextQuestion extends Question {
  type: QuestionType.TEXT;
}

export interface RatingQuestion extends Question {
  type: QuestionType.RATING;
  min?: number;
  max?: number;
}

export interface DateQuestion extends Question {
  name: string;
  type: QuestionType.DATE;
  question: string;
}

export interface ShortTextQuestion extends Question {
  type: QuestionType.SHORT_TEXT;
}
