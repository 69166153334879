import { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { useDebounce } from 'use-debounce';
import styled from 'styled-components';
import {
  Breakpoints,
  H2,
  H4,
  screenLgMin,
  screenMdMin,
} from '../../shared/styles';
import { Company } from '../authentication/types/types.auth';
import CompanySearchInput from './components/CompanySearchInput';
import { UserContext } from '../../shared/contexts/UserContext';
import ReviewDialog from '../review/components/ReviewDialog';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { FilterCompanyEnum } from './types/company.types';
import { FetchCompaniesParams, fetchCompanies } from './api/api';
import NewCompanyCard from './components/CompanyCard';
import SpinnerLoader from '../../shared/components/Loader';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--spacing-md);
  padding: var(--spacing-md) 0;

  @media ${screenLgMin} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${screenMdMin} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: ${Breakpoints.sm}px) {
    grid-template-columns: 1fr;
  }
`;

const NoResultWrapper = styled.div`
  text-align: center;
`;

const StyledNoResultHeader = styled(H4)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CompanySearchInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: var(--spacing-md);
`;

const PageWrapper = styled.div`
  padding: var(--spacing-md);
`;

const StyledHeader = styled(H2)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const CompanySearchPage = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 450);
  const [filters] = useState([
    {
      id: 0,
      filterValue: FilterCompanyEnum.ALL,
      label: 'Alphabetical',
      isActive: true,
    },
    {
      id: 1,
      filterValue: FilterCompanyEnum.MOST_RECENT,
      label: 'Latest Reviews',
      isActive: false,
    },
    {
      id: 2,
      filterValue: FilterCompanyEnum.MOST_REVIEWED,
      label: 'Most Reviews',
      isActive: false,
    },
  ]);

  useContext(UserContext);

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const companyOptions = companies
    .filter((company: Company) => company.isApproved)
    .map((company) => ({ label: company.companyName }));

  const getCompanies = async () => {
    setLoading(true);
    try {
      if (debouncedSearchTerm.trim() === '') {
        const fetchedCompanies = await fetchCompanies({
          filter: FilterCompanyEnum.ALL,
        });
        setCompanies(fetchedCompanies);
      } else {
        const res = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/company/search`,
          { searchTerm: debouncedSearchTerm }
        );
        setCompanies(Array.isArray(res.data) ? res.data : []);
      }
    } catch (error) {
      setCompanies([]);
    } finally {
      setLoading(false);
    }
  };

  const memoizedFetchCompanies = useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const activeFilter =
          filters.find((filter) => filter.isActive)?.filterValue ||
          FilterCompanyEnum.ALL;
        const params: FetchCompaniesParams = { filter: activeFilter };
        const fetchedCompanies = await fetchCompanies(params);
        setCompanies(fetchedCompanies);
      } catch (error) {
        setSnackbarConfig({
          open: true,
          type: 'error',
          message: 'Server error, please try again later',
        });
      } finally {
        setLoading(false);
      }
    };

    return fetchData;
  }, [filters]);

  useEffect(() => {
    memoizedFetchCompanies();
  }, [memoizedFetchCompanies]);

  useEffect(() => {
    getCompanies();
  }, [debouncedSearchTerm]);

  return (
    <PageWrapper>
      <SearchWrapper>
        <StyledHeader>Search Companies</StyledHeader>
        <CompanySearchInputWrapper>
          <CompanySearchInput
            value={debouncedSearchTerm}
            handleChange={setSearchTerm}
            options={companyOptions}
            label="Company Name"
          />
        </CompanySearchInputWrapper>
      </SearchWrapper>

      {loading ? (
        <SpinnerLoader />
      ) : companies.length ? (
        <Container>
          {companies.map((company, index) => (
            <NewCompanyCard key={index} company={company} />
          ))}
        </Container>
      ) : (
        <NoResultWrapper>
          <StyledNoResultHeader>
            Don't see the company you're looking for?
          </StyledNoResultHeader>
          <Button
            variant="contained"
            onClick={() => {
              setIsAddCompany(true);
              setOpenReviewDialog(true);
            }}
          >
            Add a company to our database with a review
          </Button>
        </NoResultWrapper>
      )}

      <ReviewDialog
        open={openReviewDialog}
        isAddCompany={isAddCompany}
        setOpen={setOpenReviewDialog}
      />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </PageWrapper>
  );
};

export default CompanySearchPage;
