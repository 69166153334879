import { Question } from '../../../shared/domain/form';
import { CurrentReview, ReviewStep } from '../../../shared/domain/review';
import { liabilityValueMap } from '../components/review_forms/LegalReviewForm';

export const shouldShowQuestion = (
  question: Question,
  formFields: CurrentReview
): boolean => {
  if (!formFields || !question.conditionalShowRules) return true;

  return question.conditionalShowRules.every((rule) => {
    const fieldValue = formFields[rule.field as keyof CurrentReview];
    const comparisonValue = rule.compareTo
      ? formFields[rule.compareTo as keyof CurrentReview]
      : undefined;

    if (fieldValue === undefined && comparisonValue === undefined) {
      return false;
    }

    if (rule.notValues) {
      if (!fieldValue) return false;
      return !rule.notValues.includes(fieldValue as string);
    }

    if (rule.value !== undefined) {
      return fieldValue === rule.value;
    }

    if (rule.comparison && comparisonValue !== undefined) {
      const fieldNumericValue =
        liabilityValueMap[fieldValue as string] ?? Number(fieldValue);
      const comparisonNumericValue =
        liabilityValueMap[comparisonValue as string] ?? Number(comparisonValue);

      if (
        fieldNumericValue === undefined ||
        comparisonNumericValue === undefined
      ) {
        return false;
      }

      switch (rule.comparison) {
        case 'lessThan':
          return fieldNumericValue < comparisonNumericValue;
        case 'greaterThan':
          return fieldNumericValue > comparisonNumericValue;
        case 'equalTo':
          return fieldNumericValue === comparisonNumericValue;
        default:
          return false;
      }
    }

    return true;
  });
};

export const getLoaderText = (reviewStep: ReviewStep): string => {
  switch (reviewStep) {
    case ReviewStep.PURCHASE_DETAILS:
      return 'Loading Purchase Details...';
    case ReviewStep.EVALUATION:
      return 'Loading Evaluation...';
    case ReviewStep.LEGAL:
      return 'Loading Legal Review...';
    case ReviewStep.NEGOTIATION:
      return 'Loading Negotiation...';
    case ReviewStep.RFP:
      return 'Loading RFP...';
    default:
      return 'Loading Review...';
  }
};
