import React from 'react';
import ReviewRating from '../ReviewRating';
import { RatingQuestion } from '../../../../shared/domain/form';
import { useReviewContext } from '../../context/ReviewContext';
import { CurrentReview } from '../../../../shared/domain/review';

type ReviewRatingQuestionProps = {
  handleRatingChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  formFields: any;
  question: RatingQuestion;
  errors: Record<string, string>;
  currentReview?: CurrentReview;
};

const ReviewRatingQuestion = (props: ReviewRatingQuestionProps) => {
  const { handleRatingChange, formFields, currentReview, question, errors } =
    props;

  const { isViewMode } = useReviewContext();

  return (
    <ReviewRating
      handleChange={handleRatingChange}
      errors={errors}
      isReadOnly={isViewMode}
      value={
        formFields[question.name] ||
        currentReview?.ratings?.find(
          (responseRating) => responseRating?.type === question.responseName
        )?.rating ||
        0
      }
      label={question.question}
      name={question.name}
    />
  );
};

export default ReviewRatingQuestion;
