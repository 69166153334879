import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';
import Select from '../../../shared/components/Select';
import { P } from '../../../shared/styles';
import {
  AuthEnum,
  COMPANY_SIZE_OPTIONS,
  FormField,
  FormFieldsValues,
  INDUSTRY_SELECT_OPTIONS,
} from '../types/types.auth';

type AuthFormFieldsProps = {
  formFields: Array<FormField>;
  handleFormFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => void;
  defaultValues?: FormFieldsValues;
  isEdit?: boolean;
  industryValue?: string;
  authType?: AuthEnum;
};

const StyledText = styled(P)`
  text-align: center;
`;

const AuthFormFields = (props: AuthFormFieldsProps) => {
  const {
    handleFormFieldChange,
    formFields,
    defaultValues,
    isEdit,
    industryValue,
    authType,
  } = props;

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => {
    handleFormFieldChange(e, selectedOptions);
  };

  return (
    <Grid container spacing={2}>
      {formFields.map((field) => {
        return (
          <Grid item xs={field.xs} sm={field.sm} key={field.name}>
            {field.name === 'industry' ? (
              <Select
                label={field.label}
                name={field.name}
                required={true}
                currentValue={industryValue}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                options={INDUSTRY_SELECT_OPTIONS}
                onChange={onChange}
              />
            ) : field.name === 'companySize' ? (
              <Select
                label={field.label}
                name={field.name}
                required={true}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                options={COMPANY_SIZE_OPTIONS}
                onChange={onChange}
              />
            ) : (
              <TextField
                name={field.name}
                fullWidth
                disabled={field.name === 'email' && isEdit}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                type={field.type}
                label={field.label}
                onChange={onChange}
                required
              />
            )}
          </Grid>
        );
      })}
      {authType && authType === AuthEnum.SIGN_UP && (
        <Grid item>
          <StyledText>
            Password must be longer than 8 characters, and include both an
            uppercase, lowercase letter and a number.
          </StyledText>
        </Grid>
      )}
    </Grid>
  );
};
export default AuthFormFields;
