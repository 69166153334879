import {
  MultipleChoiceQuestion,
  Question,
  QuestionType,
  QuestionValidationRules,
  RatingQuestion,
  ShortTextQuestion,
  TextQuestion,
} from '../../../shared/domain/form';
import { RatingAspect } from '../../../shared/domain/review';

export const BASIC_REVIEW_FORM_FIELDS: Question[] = [
  {
    name: 'dealArea',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Which part of the organization did you sell to?',
    options: [
      'Customer Success',
      'Engineering',
      'Finance',
      'HR',
      'Legal',
      'Marketing',
      'Operations',
      'Product',
      'Sales',
      'IT',
      'Security',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'dealSize',
    question: 'What was the annual contract value of the deal?',
    type: QuestionType.MULTIPLE_CHOICE,
    options: [
      '0 - 25k',
      `25k - 50k`,
      `50k - 100k`,
      `100k - 250k`,
      `250k - 500k`,
      `500k - 1M`,
      '1MM+',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,

  {
    name: 'dealType',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What type of deal is this?',
    options: ['New Business', 'Renewal', 'Expansion/Upsell'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'dealLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Term of the deal?',
    options: ['Less than 1 year', '1 Year', '2 Years', '3 Years+'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'dealClose',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you win the deal?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'dealDate',
    type: QuestionType.MULTIPLE_CHOICE,
    validationRules: [QuestionValidationRules.REQUIRED],
    question: 'When was the deal sold?',
    options: Array.from(
      { length: 5 },
      (_, i) => `${new Date().getFullYear() - i}`
    ),
  } as MultipleChoiceQuestion,
  {
    name: 'dealRfp',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did this deal include an RFP?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,

  {
    name: 'commentBuyerWell',
    type: QuestionType.TEXT,
    question: 'What did the buyer do well?',
    validationRules: [
      QuestionValidationRules.REQUIRED,
      QuestionValidationRules.MINIMUM_CHARACTERS,
    ],
  } as TextQuestion,
  {
    name: 'commentBuyerBetter',
    question: 'What could the buyer have done better?',
    validationRules: [
      QuestionValidationRules.REQUIRED,
      QuestionValidationRules.MINIMUM_CHARACTERS,
    ],
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    name: 'commentWinLose',
    question: 'Why did you win/lose the deal?',
    validationRules: [
      QuestionValidationRules.REQUIRED,
      QuestionValidationRules.MINIMUM_CHARACTERS,
    ],
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    name: 'commentAdvice',
    question: 'What advice do you have for the next seller?',
    validationRules: [
      QuestionValidationRules.REQUIRED,
      QuestionValidationRules.MINIMUM_CHARACTERS,
    ],
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    question: 'Transparency in Decision Making',
    name: 'transparency',
    type: QuestionType.RATING,
    validationRules: [QuestionValidationRules.REQUIRED],

    responseName: RatingAspect.TRANSPARENCY,
  } as RatingQuestion,
  {
    question: 'Clarity of Needs',
    name: 'clarity',
    type: QuestionType.RATING,
    validationRules: [QuestionValidationRules.REQUIRED],
    responseName: RatingAspect.CLARITY,
  } as RatingQuestion,
  {
    question: 'Technical Competence',
    name: 'understanding',
    type: QuestionType.RATING,
    validationRules: [QuestionValidationRules.REQUIRED],
    responseName: RatingAspect.UNDERSTANDING,
  } as RatingQuestion,
  {
    question: 'Responsiveness',
    name: 'responsiveness',
    type: QuestionType.RATING,
    validationRules: [QuestionValidationRules.REQUIRED],
    responseName: RatingAspect.RESPONSIVENESS,
  } as RatingQuestion,
];

export const PURCHASE_DETAILS_FORM_FIELDS = [
  {
    name: 'economicBuyerName',
    type: QuestionType.SHORT_TEXT,
    question: 'What was the title of the economic buyer?',
    validationRules: [QuestionValidationRules.REQUIRED],
  } as ShortTextQuestion,
  {
    name: 'championName',
    type: QuestionType.SHORT_TEXT,
    question: 'What was the title of your champion?',
    validationRules: [QuestionValidationRules.REQUIRED],
  } as ShortTextQuestion,
  {
    name: 'championEffectiveness',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how effective was your champion at getting the deal done?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'definedBudget',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was there a defined budget for this purchase?',
    options: ['Yes', 'No', 'Unknown'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'salesCycleLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'How long was the sales cycle from start to finish?',
    options: [
      '< 1 Month',
      '1 - 3 Months',
      '3 - 6 Months',
      '6 - 12 Months',
      '12+ Months',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'buyerFiscalYearEnd',
    type: QuestionType.MULTIPLE_CHOICE,
    question: "When does the buyer's fiscal year end?",
    options: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Unknown',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'paymentCycle',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the customer agree to pay monthly, quarterly, annually or other?',
    options: ['Monthly', 'Quarterly', 'Annually', 'Other', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
];

export const EVALUATION_FORM_FIELDS = [
  {
    name: 'buyerReplacingVendor',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was the buyer replacing an incumbent vendor?',
    options: ['Yes', 'No', 'Unknown'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'submitRoi',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Were you required to submit an ROI or other business justification to win this deal?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'requireTrial',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did the customer request a trial?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'trialAccommodated',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was a trial accommodated?',
    options: ['Yes', 'No', 'N/A'],
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'requireTrial', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'trialLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'How long was the trial?',
    options: ['< 1 Week', '2-4 Weeks', '2-3 Months', '3-6 Months', '>6 Months'],
    conditionallyShow: true,
    validationRules: [QuestionValidationRules.REQUIRED],
    conditionalShowRules: [
      { field: 'requireTrial', value: 'Yes' },
      { field: 'trialAccommodated', value: 'Yes' },
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'noDecisionMakers',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'How many decision makers were involved in the evaluation process?',
    options: ['1', '2-3', '4-5', '5+'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,

  {
    name: 'durationSecurityReview',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What was the duration of the security review?',
    validationRules: [QuestionValidationRules.REQUIRED],
    options: [
      'Not Required',
      '< Week',
      '2 Weeks',
      '3 Weeks',
      '4+ Weeks',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'securityDifficulty',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how difficult was it to pass the security review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
    conditionallyShow: true,
    validationRules: [QuestionValidationRules.REQUIRED],
    conditionalShowRules: [
      {
        field: 'durationSecurityReview',
        notValues: ['N/A', 'Not Required'],
      },
    ],
  } as MultipleChoiceQuestion,

  {
    name: 'criteriaArticulatedByBuyer',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was the evaluation criteria clearly articulated by the buyer?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,

  {
    name: 'articulatingCriteriaComment',
    type: QuestionType.TEXT,
    question:
      'What could the buyer have done better when articulating the evaluation criteria?',
    conditionallyShow: true,
    validationRules: [QuestionValidationRules.REQUIRED],
    conditionalShowRules: [
      { field: 'criteriaArticulatedByBuyer', value: 'No' },
    ],
  } as TextQuestion,
  {
    name: 'criteriaChangeComment',
    type: QuestionType.TEXT,
    question: 'Did the buyer change the evaluation criteria, and if so, how?',
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'negotiateTrialComment',
    type: QuestionType.TEXT,
    question:
      'Please share how you were able to negotiate the customer out of the request for a trial?',
    conditionalShowRules: [
      { field: 'requireTrial', value: 'Yes' },
      { field: 'trialAccommodated', value: 'No' },
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'securityRequirementsComment',
    type: QuestionType.TEXT,
    question:
      'Are there any specific requirements you must meet to pass the security review?',
    conditionallyShow: true,
    conditionalShowRules: [
      {
        field: 'durationSecurityReview',
        notValues: ['N/A', 'Not Required'],
      },
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'roiJustificationKPIsComment',
    type: QuestionType.TEXT,
    question:
      'What KPI’s or outcomes were used to justify your ROI or business justification submission?',
    conditionallyShow: true,
    conditionalShowRules: [
      {
        field: 'submitRoi',
        value: 'Yes',
      },
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
];

export const LEGAL_FORM_FIELDS = [
  {
    name: 'legalReviewLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What was the duration of the legal review?',
    options: [
      'Not Required',
      '1-2 Weeks',
      '3-4 Weeks',
      '1-3 Months',
      '3-6 Months',
      '>6 Months',
      'N/A',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'legalReviewDifficulty',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how difficult was it to pass the legal review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
    conditionallyShow: true,
    conditionalShowRules: [
      {
        field: 'legalReviewLength',
        notValues: ['N/A', 'Not Required'],
      },
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'marketingPermission',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did they give you permission to use their logo and/or name in marketing materials?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'autoRenewalClause',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was there an auto-renewal clause in the contract?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'paperworkOption',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the customer agree to your companies contracting paperwork or did they require use of their own?',
    options: [
      'Our contracting paperwork',
      'Their contracting paperwork',
      'N/A',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'customerAskLiability',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'What did the customer ask for in Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'endAskLiability',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'What did you end up with with Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'liabilityComment',
    type: QuestionType.TEXT,
    question:
      'Were there any carve outs and/or supercaps to the LoL/Indemnification agreed upon? Please be specific?',
  } as TextQuestion,
  {
    name: 'notableGenComment',
    validationRules: [QuestionValidationRules.REQUIRED],
    type: QuestionType.TEXT,
    question:
      "What were the legal team's main points of contention, and how did you mitigate/address them?",
  } as TextQuestion,
  {
    name: 'liabilityStrategiesComment',
    type: QuestionType.TEXT,
    validationRules: [QuestionValidationRules.REQUIRED],
    question:
      'Please share any details or strategies that helped you negotiate a lower Liability/Indemnification request.',
    conditionallyShow: true,
    conditionalShowRules: [
      {
        field: 'endAskLiability',
        compareTo: 'customerAskLiability',
        comparison: 'lessThan',
      },
    ],
  } as TextQuestion,
];

export const NEGOTIATION_FORM_FIELDS = [
  {
    name: 'terminationConvenience',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did the buyer request a termination for convenience clause?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'forcedTerminationAcceptance',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you accept this request?',
    options: ['Yes', 'No', 'N/A'],
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'terminationConvenience', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'noticeSelection',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you receive notice that you were the selected vendor?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'noticeHonored',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was this notice honored?',
    options: ['Yes', 'No'],
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'noticeSelection', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,

  {
    name: 'procurementThreshold',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the buyer state a threshold at which procurement would be required to get involved?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'procurementThresholdValue',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'What was the threshold the buyer stated that procurement would need to get involved at?',
    options: ['100', '200', '500', '1,000', '5,000', '10,000', '50,000'],
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'procurementThreshold', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'procurementFairness',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did procurement treat you fair and honest?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,

  {
    name: 'procurementTactics',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did procurement/buyer use any tactics to force you to take unfavorable terms and/or additional discounts?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'competitiveBids',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did procurement require competitive bids?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'fundsReleased',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was a business case required to get funds released?',
    options: ['Yes', 'No', 'N/A'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'discountGiven',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What discount did you ultimately give to win the deal?',
    options: [
      '0%',
      '1% - 10%',
      '11% - 20%',
      '21 - 30%',
      '31 - 40%',
      '41 - 50%',
      '51%+',
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'forcedTerminationAcceptanceComment',
    type: QuestionType.TEXT,
    question:
      'How did you negotiate the termination for convenience clause out of the contract?',
    conditionallyShow: true,
    conditionalShowRules: [
      { field: 'terminationConvenience', value: 'Yes' },
      { field: 'forcedTerminationAcceptance', value: 'No' },
    ],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'negotiationTipsComment',
    question: 'Any tips to other sellers to help negotiate with this customer?',
    type: QuestionType.TEXT,
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'fairnessComment',
    question:
      'Please add details regarding the procurement process, such as the honesty of the buyer, any tactics used, etc.',
    type: QuestionType.TEXT,
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    name: 'buyerTacticsComment',
    question:
      'Please share what tactics the buyer used and the strategies you used to combat them.  Were your strategies successful?',
    type: QuestionType.TEXT,
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'procurementTactics', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
];

export const RFP_FORM_FIELDS = [
  {
    name: 'rfpWin',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you win the RFP?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpInvolvement',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Were you involved in the RFP process?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpInfluence',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you have influence over the RFP?',
    options: ['Yes', 'No'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'How long was the RFP from initiation to final decision?',
    options: ['< 1 Month', '1 - 3 Months', '3 - 6 Months', '6+ Months'],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as MultipleChoiceQuestion,
  {
    question: 'Through what format did the buyer accept submission?',
    type: QuestionType.TEXT,
    name: 'acceptanceFormatComment',
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    question: 'Any tips to other sellers to help negotiate with this customer?',
    type: QuestionType.TEXT,
    name: 'tipsComment',
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
  {
    question: 'Please describe how you were able to influence the RFP process.',
    type: QuestionType.TEXT,
    name: 'influenceRfpProcessComment',
    conditionallyShow: true,
    conditionalShowRules: [{ field: 'rfpInfluence', value: 'Yes' }],
    validationRules: [QuestionValidationRules.REQUIRED],
  } as TextQuestion,
];
