import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import { UserContext } from '../../../shared/contexts/UserContext';
import { ReviewStep } from '../../../shared/domain/review';
import NegotiationReviewForm from './review_forms/NegotiationReviewForm';
import PurchaseDetailsReviewForm from './review_forms/PurchaseDetailsReviewForm';
import ReviewDialogHeader from './ReviewDialogHeader';
import ReviewStepper from './ReviewStepper';
import RfpReviewForm from './review_forms/RfpReviewForm';
import ModalTransition from '../../../shared/ModalTransition';
import { useReviewContext } from '../context/ReviewContext';
import Loader from '../../../shared/components/Loader';
import { getLoaderText } from '../helpers/ReviewFormHelpers';
import BasicReviewForm from './review_forms/BasicReviewForm';
import EvaluationReviewForm from './review_forms/EvaluationReviewForm';
import LegalReviewForm from './review_forms/LegalReviewForm';

type ReviewDialogProps = {
  open: boolean;
  companyName?: string;
  companyId?: number;
  setOpen: (isOpen: boolean) => void;
  isAddCompany?: boolean;
};

const ReviewDialog = (props: ReviewDialogProps) => {
  const { open, setOpen, companyName, companyId, isAddCompany } = props;
  const [rfp, setRfp] = useState(false);

  const { user } = useContext(UserContext);
  const {
    handleRatingSuccess,
    handleServerError,
    fetchAllReviews,
    isViewMode,
    reviewId,
    reviewStep,
    setReviewStep,
    loading,
  } = useReviewContext();

  const [hasSeenAIValidation, setHasSeenAIValidation] = useState({
    //only including steps where we show the AI validation modal
    evaluation: false,
    legalReview: false,
    negotiation: false,
  });
  const [globalAIValidationPreference, setGlobalAIValidationPreference] =
    useState<boolean | null>(null);

  const [basicFormLoading, setBasicFormLoading] = useState(false);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        if (user) {
          const res = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}/user/${user?.id}/preferences`,
            {
              headers: {
                Authorization: user?.idToken,
              },
            }
          );
          setGlobalAIValidationPreference(res.data.showAIValidation);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
        //If there is an error, we will default to showing the AI validation modal
        setGlobalAIValidationPreference(true);
      }
    };
    fetchUserPreferences();
  }, [reviewStep]);

  const shouldShowAIValidation = (
    formName: keyof typeof hasSeenAIValidation
  ) => {
    //if the global preference is false, we should not show the AI validation modal
    if (globalAIValidationPreference === false) return false;
    //otherwise, use the step-specific preference
    return !hasSeenAIValidation[formName as keyof typeof hasSeenAIValidation];
  };

  const updateAiValidationShown = (
    formName: keyof typeof hasSeenAIValidation
  ) => {
    setHasSeenAIValidation((prev) => ({ ...prev, [formName]: true }));
  };

  const handleClose = () => {
    handleRatingSuccess();
    setOpen(false);
  };

  const handleBack = (step: ReviewStep) => {
    setReviewStep(step);
  };

  const handleViewMode = (key: ReviewStep) => {
    if (isViewMode && shouldCompleteReview(key)) {
      handleRatingSuccess();
      setOpen(false);
      return;
    }
    setReviewStep(reviewStep + 1);
  };

  const shouldCompleteReview = (key: ReviewStep) =>
    key === ReviewStep.RFP || (key === ReviewStep.NEGOTIATION && !rfp);

  const submitReviewForm = async (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    key: ReviewStep,
    isReviewDeepDive?: boolean
  ) => {
    if (!user?.idToken) return;
    e.preventDefault();

    if (isViewMode) {
      handleViewMode(key);
      return;
    }

    const reviewInput = {
      ...formFields,
      key,
      reviewId,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review`,
        reviewInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );

      if (isReviewDeepDive && shouldCompleteReview(key)) {
        await completeReview();
      } else {
        setReviewStep(reviewStep + 1);
      }
    } catch (error) {
      handleServerError();
      setOpen(false);
    }
  };

  const completeReview = () => {
    if (!companyId) return;
    fetchAllReviews(companyId);
    handleRatingSuccess(true);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={ModalTransition}
    >
      <ReviewDialogHeader
        handleClose={handleClose}
        companyName={companyName || 'a New Company'}
        isDeepDive={!!reviewStep}
      />

      {loading ? (
        <Loader loaderText={getLoaderText(reviewStep)} />
      ) : (
        <>
          {reviewStep !== ReviewStep.BASIC_REVIEW && (
            <ReviewStepper handleBack={handleBack} rfp={rfp} />
          )}
          {reviewStep === ReviewStep.BASIC_REVIEW && (
            <BasicReviewForm
              companyId={companyId || 0}
              companyName={companyName || 'New Company'}
              setBasicFormLoading={setBasicFormLoading}
              cancelReview={() => {
                handleServerError();
                setOpen(false);
              }}
              handleReviewSuccess={() => {
                handleRatingSuccess(true);
                setOpen(false);
              }}
              setRfp={setRfp}
              isAddCompany={isAddCompany}
            />
          )}
          {reviewStep === ReviewStep.PURCHASE_DETAILS && (
            <PurchaseDetailsReviewForm
              submitReviewForm={submitReviewForm}
              handleViewMode={handleViewMode}
            />
          )}
          {reviewStep === ReviewStep.EVALUATION && (
            <EvaluationReviewForm
              submitReviewForm={submitReviewForm}
              handleViewMode={handleViewMode}
              companyName={companyName}
              shouldShowAIValidation={shouldShowAIValidation('evaluation')}
              updateAiValidationShown={() =>
                updateAiValidationShown('evaluation')
              }
            />
          )}
          {reviewStep === ReviewStep.LEGAL && (
            <LegalReviewForm
              submitReviewForm={submitReviewForm}
              handleViewMode={handleViewMode}
              shouldShowAIValidation={shouldShowAIValidation('legalReview')}
              updateAiValidationShown={() =>
                updateAiValidationShown('legalReview')
              }
            />
          )}
          {reviewStep === ReviewStep.NEGOTIATION && (
            <NegotiationReviewForm
              submitReviewForm={submitReviewForm}
              handleViewMode={handleViewMode}
              rfp={rfp}
              setOpen={setOpen}
              shouldShowAIValidation={shouldShowAIValidation('negotiation')}
              updateAiValidationShown={() =>
                updateAiValidationShown('negotiation')
              }
            />
          )}
          {reviewStep === ReviewStep.RFP && (
            <RfpReviewForm
              submitReviewForm={submitReviewForm}
              handleViewMode={handleViewMode}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default ReviewDialog;
