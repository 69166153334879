import React from 'react';
import styled from 'styled-components';
import { ClipLoader } from 'react-spinners';
import { Colors } from '../styles';

type SpinnerLoaderProps = {
  loaderText?: string;
};

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 100%;
`;

const LoaderText = styled.div`
  margin-top: 16px;
  font-size: 18px;
  color: #333;
`;

const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpinnerLoader = (props: SpinnerLoaderProps) => {
  const { loaderText } = props;
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <ClipLoader color={Colors.lightBlue} size={50} />
        <LoaderText>
          {loaderText ? loaderText : 'Loading, please wait...'}
        </LoaderText>
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default SpinnerLoader;
