import { Button, Grid } from '@mui/material';
import axios from 'axios';
import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import _ from 'underscore';
import DraggableList, {
  Item,
} from '../../../../shared/components/DraggableList';
import { UserContext } from '../../../../shared/contexts/UserContext';
import {
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
} from '../../../../shared/domain/form';
import {
  ReviewStep,
  EvaluationReview,
  CurrentReview,
} from '../../../../shared/domain/review';
import { screenMdMin } from '../../../../shared/styles';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import { useReviewContext } from '../../context/ReviewContext';
import { EVALUATION_FORM_FIELDS } from '../../data';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import useValidation from '../../hooks/useValidation';
import { Container, StyledTextField } from '../../styles/review.styles';
import AIValidationModal from '../AIValidationModal';
import MultipleChoiceReviewQuestion, {
  StyledQuestionText,
} from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';
import ReviewFormButtons from '../ReviewFormButtons';

let nextApproverId = 1;
const nextPlatformId = 1;

type EvaluationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  handleViewMode: (key: ReviewStep) => void;
  companyName?: string;
  shouldShowAIValidation: boolean;
  updateAiValidationShown: () => void;
};

const ComponentWrapper = styled.div`
  @media ${screenMdMin} {
    display: flex;
  }
`;

const StyledButton = styled(Button)`
  margin-left: var(--spacing-md);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
  @media ${screenMdMin} {
    margin-top: var(--spacing-sm);
  }
`;

const nextButtonText = 'Move onto Legal';

const EvaluationReviewForm = (props: EvaluationReviewProps) => {
  const {
    submitReviewForm,
    handleViewMode,
    shouldShowAIValidation,
    updateAiValidationShown,
    companyName,
  } = props;

  const { currentReview, isViewMode } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<EvaluationReview>(currentReview ?? ({} as EvaluationReview));

  const [currentApprover, setCurrentApprovers] = useState<string>('');
  const [approvers, setApprovers] = useState<Item[]>([]);
  const [platforms, setPlatforms] = useState<Item[]>([]);
  const [currentPlatform, setCurrentPlatform] = useState<string>('');

  const { errors, validateFields } = useValidation(
    formFields,
    EVALUATION_FORM_FIELDS
  );

  const [aiValidationResult, setAiValidationResult] = useState<string | null>(
    null
  );

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    extendFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleApproversChange = (approverName: string) => {
    setApprovers([
      ...approvers,
      { id: String(nextApproverId++), text: approverName },
    ]);
    setCurrentApprovers('');
  };

  const handlePlatformsChange = (platformName: string) => {
    setPlatforms([
      ...platforms,
      { id: String(nextPlatformId), text: platformName },
    ]);
    setCurrentPlatform('');
  };

  const isEvaluationReview = (
    review: CurrentReview | null
  ): review is EvaluationReview =>
    !!review && 'approversList' in review && 'platFormList' in review;

  useEffect(() => {
    const updatedApproversList = approvers.map((approver) => approver.text);
    if (!_.isEqual(formFields.approversList, updatedApproversList)) {
      setFormFields((prev: any) => ({
        ...prev,
        approversList: updatedApproversList,
      }));
    }
  }, [approvers]);

  useEffect(() => {
    const updatedPlatformsList = platforms.map((platform) => platform.text);
    if (!_.isEqual(formFields.platFormList, updatedPlatformsList)) {
      setFormFields((prev: any) => ({
        ...prev,
        platFormList: updatedPlatformsList,
      }));
    }
  }, [platforms]);

  useEffect(() => {
    if (currentReview && isEvaluationReview(currentReview)) {
      const updatedApprovers =
        currentReview.approversList?.map((approver, index) => ({
          id: String(index),
          text: approver,
        })) || [];
      const updatedPlatforms =
        currentReview.platFormList?.map((platform, index) => ({
          id: String(index),
          text: platform,
        })) || [];

      if (!_.isEqual(approvers, updatedApprovers)) {
        setApprovers(updatedApprovers);
      }
      if (!_.isEqual(platforms, updatedPlatforms)) {
        setPlatforms(updatedPlatforms);
      }

      if (!_.isEqual(formFields, currentReview)) {
        setFormFields(currentReview);
      }
    } else {
      setFormFields({} as EvaluationReview);
      setApprovers([]);
      setPlatforms([]);
    }
  }, [currentReview]);

  const submitEvaluationReview = async (e: any) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }
    if (shouldShowAIValidation === false) {
      handleCloseAndSubmitModal(e);
      return;
    }
    try {
      const dataToSend = {
        ...formFields,
        companyName: companyName ?? 'new company',
        formName: 'evaluation',
      };
      //Open the modal before making the call to the backend to improve percieved performance
      setIsModalOpen(true);
      setAiValidationResult(null); //so old data is cleared out in event of re-running it
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review/ai-validate`,
        dataToSend,
        {
          headers: {
            Authorization: user?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );

      setAiValidationResult(JSON.stringify(response.data.validationMessage));

      updateAiValidationShown();
    } catch (error) {
      console.error('Error validating form:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCloseAndSubmitModal = (e: React.FormEvent<HTMLFormElement>) => {
    setIsModalOpen(false);
    submitReviewForm(e, formFields, ReviewStep.EVALUATION, true);
  };

  return (
    <Container>
      <form>
        <Grid container>
          {EVALUATION_FORM_FIELDS.filter(
            (q): q is MultipleChoiceQuestion =>
              q.type === QuestionType.MULTIPLE_CHOICE
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question) => (
              <MultipleChoiceReviewQuestion
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isNarrow={question.options.length > 8}
              />
            ))}
        </Grid>
        <Grid container>
          {EVALUATION_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isViewMode={false}
                index={i}
              />
            ))}
        </Grid>
        &nbsp;
        <ComponentWrapper>
          <Grid style={{ width: '100%' }} item sm={6} xs={12}>
            <StyledQuestionText
              style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}
            >
              Please add all approvers titles (no names) in chronological order:
              &nbsp;
            </StyledQuestionText>
            <StyledTextField
              isWide
              value={currentApprover}
              name="approversList"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentApprovers(e.target.value)
              }
              placeholder="Once added, a list will populate below"
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />

            <StyledButton
              variant="outlined"
              disabled={!currentApprover}
              onClick={() => handleApproversChange(currentApprover)}
            >
              Add Approver
            </StyledButton>

            <DraggableList items={approvers} />
          </Grid>
          <Grid style={{ width: '100%' }} item sm={6} xs={12}>
            <StyledQuestionText
              style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}
            >
              Please add all platforms you were required to integrate with as
              part of the process:
            </StyledQuestionText>
            <StyledTextField
              isWide
              value={currentPlatform}
              name="platFormList"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentPlatform(e.target.value)
              }
              required
              placeholder="Once added, a list will populate below"
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />

            <StyledButton
              variant="outlined"
              disabled={!currentPlatform}
              onClick={() => handlePlatformsChange(currentPlatform)}
            >
              Add Platform
            </StyledButton>
            <DraggableList items={platforms} />
          </Grid>
        </ComponentWrapper>
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? handleViewMode(ReviewStep.EVALUATION)
              : submitEvaluationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={nextButtonText}
          onSubmit={(e) =>
            submitReviewForm(e, formFields, ReviewStep.EVALUATION)
          }
        />
      </form>
      <AIValidationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCloseAndSubmitModal}
        validationResult={aiValidationResult}
        nextButtonText={nextButtonText}
      />
    </Container>
  );
};

export default EvaluationReviewForm;
