import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, TextField, FormControl, Autocomplete } from '@mui/material';
import styled from 'styled-components';
import { P } from '../../../shared/styles';
import { Company } from '../../authentication/types/types.auth';
import { useExtendState } from '../../../shared/utils/ExtendState';
import { COMPANY_FIELDS_MAPPINGS, usaStates } from '../types/admin.types';
import Select from '../../../shared/components/Select';
import { Textarea } from '../../../shared/components/TextArea';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ModalTransition from '../../../shared/ModalTransition';

type FieldName = keyof Company;

type ApproveCompanyModalProps = {
  isOpen: boolean;
  setIsOpen: () => void;
  companyName: string;
  approveCompanyRecord: (
    e: React.FormEvent<HTMLFormElement>,
    company: Company
  ) => void;
};

const StyledForm = styled.form`
  max-width: 85%;
  margin: 0 auto;
  padding: var(--spacing-md);
`;

const StyledText = styled(P)`
  margin: var(--spacing-md) 0;
`;

const Title = styled.h2`
  text-align: center;
  text-decoration: underline;
  margin: auto;
  margin-top: var(--spacing-lg);
`;

const StyledSubmitButton = styled(Button)`
  && {
    width: 150px;
    margin: var(--spacing-md) auto;
  }
`;

const ApproveCompanyModal = (props: ApproveCompanyModalProps) => {
  const { isOpen, setIsOpen, companyName, approveCompanyRecord } = props;

  const [existingCompanyData, setExistingCompanyData] = useState<Company>();
  const [formFields, extendFormFields, setFormFields] = useExtendState<Company>(
    existingCompanyData || ({} as Company)
  );

  const getCompanyInfo = async (companyName: string) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/company/${companyName}`
      );
      setExistingCompanyData(res.data);
      return res.data;
    } catch (error) {}
  };

  useEffect(() => {
    if (!companyName) return;

    getCompanyInfo(companyName);
  }, [companyName]);

  return (
    <>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={setIsOpen}
        TransitionComponent={ModalTransition}
      >
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={setIsOpen}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <StyledForm
          onSubmit={(e) => {
            approveCompanyRecord(e, formFields);
            setFormFields({});
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Title>{formFields?.companyName || companyName}</Title>
              <StyledText>
                Please fill out all fields for the above company. Note that this
                form will override existing information (including the company
                name), and once submitted, this company will become live in our
                database.
              </StyledText>

              <StyledText>
                Please also make a note to upload an image to the weTransfer
                folder - this file must match the company name
              </StyledText>
            </Grid>
            {COMPANY_FIELDS_MAPPINGS.map((field, i) => (
              <Grid item xs={field.name === 'profile' ? 12 : 6} key={i}>
                {field.componentType === 'TextField' && (
                  <TextField
                    fullWidth
                    label={field.label}
                    name={field.name}
                    value={
                      existingCompanyData &&
                      existingCompanyData[field.name as FieldName]
                        ? existingCompanyData[field.name as FieldName]
                        : ((formFields[field.name as FieldName] ||
                            '') as Company[FieldName])
                    }
                    onChange={(e) =>
                      extendFormFields({ [e.target.name]: e.target.value })
                    }
                    variant="outlined"
                    required={field.required}
                    inputProps={{
                      minLength: [field.minLength],
                      maxLength: [field.maxLength],
                    }}
                  />
                )}

                {field.componentType === 'Select' && (
                  <Select
                    label={field.label}
                    name={field.name}
                    onChange={(e) =>
                      extendFormFields({ [e.target.name]: e.target.value })
                    }
                    required={field.required}
                    options={field.options}
                  />
                )}

                {field.componentType === 'AutoComplete' && (
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField name="state" {...params} label="State" />
                    )}
                    onChange={(e, value) =>
                      extendFormFields({ [field.name]: value?.abbreviation })
                    }
                    options={usaStates}
                    getOptionLabel={(option) => option.name}
                  />
                )}

                {field.componentType === 'TextArea' && (
                  <FormControl style={{ width: '100%' }}>
                    <Textarea
                      name={field.name}
                      placeholder="Profile"
                      style={{ resize: 'none' }}
                      minRows={3}
                      onChange={(e) =>
                        extendFormFields({ [e.target.name]: e.target.value })
                      }
                      required={field.required}
                    />
                  </FormControl>
                )}
              </Grid>
            ))}
          </Grid>
          <StyledSubmitButton variant="contained" type="submit">
            Submit
          </StyledSubmitButton>
        </StyledForm>
      </Dialog>
    </>
  );
};

export default ApproveCompanyModal;
