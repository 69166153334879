import React, {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  BaseReview,
  BasicReview,
  CurrentReview,
  ReviewStep,
} from '../../../shared/domain/review';
import { SnackBarConfig } from '../../../shared/components/Snackbar';
import { UserContext } from '../../../shared/contexts/UserContext';
import { getAllCompanyBasicReviews } from '../api/api';

interface ReviewContextType {
  snackbarConfig: SnackBarConfig | undefined;
  setSnackbarConfig: Dispatch<SetStateAction<SnackBarConfig | undefined>>;
  reviewStep: ReviewStep;
  setReviewStep: Dispatch<SetStateAction<number>>;
  reviewId: number | null;
  setReviewId: Dispatch<SetStateAction<number | null>>;
  currentReview: CurrentReview;
  handleServerError: () => void;
  handleRatingSuccess: (showSnackbar?: boolean) => void;
  allCompanyReviews: Array<BasicReview> | null;
  fetchAllReviews: (companyId: number) => void;
  isViewMode: boolean;
  setIsViewMode: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export const ReviewContext = createContext<ReviewContextType | undefined>(
  undefined
);

export const useReviewContext = () => {
  const context = useContext(ReviewContext);
  if (!context) {
    throw new Error('useReview must be used within a ReviewProvider');
  }
  return context;
};

const ReviewProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentReview, setCurrentReview] = useState<BaseReview | null>(null);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [reviewStep, setReviewStep] = useState<number>(0);
  const [reviewId, setReviewId] = useState<number | null>(null);
  const [allCompanyReviews, setAllCompanyReviews] = useState<BasicReview[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  const fetchAllReviews = async (companyId: number) => {
    if (!user?.idToken) return;
    try {
      const res: BasicReview[] | null = await getAllCompanyBasicReviews(
        companyId,
        user?.idToken
      );
      setAllCompanyReviews(res || []);
    } catch (error) {
      handleServerError();
    }
  };

  const handleServerError = () => {
    setSnackbarConfig({
      type: 'error',
      message: 'An error has occurred, please try again later.',
      open: true,
    });
    setCurrentReview(null);
    setIsViewMode(false);
    setReviewStep(ReviewStep.BASIC_REVIEW);
  };

  const handleRatingSuccess = (showSnackbar?: boolean) => {
    if (!isViewMode && showSnackbar) {
      setSnackbarConfig({
        type: 'success',
        message: 'Review successfully submitted, thank you!',
        open: true,
      });
    }

    setCurrentReview(null);
    setReviewId(null);
    setIsViewMode(false);
    setReviewStep(ReviewStep.BASIC_REVIEW);
  };

  const getCurrentReview = async (key?: ReviewStep) => {
    if (!user?.idToken) return;
    setLoading(true);
    try {
      const params = key !== undefined ? { key } : {};
      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/review/${reviewId}`,
        {
          params,
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setCurrentReview(res.data);
    } catch (error) {
      setSnackbarConfig({
        type: 'error',
        message: 'An error has occurred, please try again later.',
        open: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!reviewId) return;
    getCurrentReview(reviewStep);
  }, [reviewId, reviewStep]);

  const value = {
    currentReview,
    setCurrentReview,
    setSnackbarConfig,
    handleServerError,
    handleRatingSuccess,
    snackbarConfig,
    allCompanyReviews,
    fetchAllReviews,
    isViewMode,
    setIsViewMode,
    reviewId,
    setReviewId,
    reviewStep,
    setReviewStep,
    loading,
    setLoading,
  };

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
};

export default ReviewProvider;
