import React, { useEffect } from 'react';
import _ from 'underscore';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import {
  PurchaseDetailsReview,
  ReviewStep,
} from '../../../../shared/domain/review';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import { PURCHASE_DETAILS_FORM_FIELDS } from '../../data';
import { Container, StyledTextField } from '../../styles/review.styles';
import ReviewFormButtons from '../ReviewFormButtons';
import { useReviewContext } from '../../context/ReviewContext';
import useValidation from '../../hooks/useValidation';
import MultipleChoiceReviewQuestion, {
  QuestionWrapper,
  QuestionTextWrapper,
  StyledQuestionText,
} from '../question_types/ReviewMultipleChoiceQuestion';
import {
  MultipleChoiceQuestion,
  QuestionType,
  ShortTextQuestion,
} from '../../../../shared/domain/form';
import ReviewShortTextQuestion from '../question_types/ReviewShortTextQuestion';

type PurchaseDetailsReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  handleViewMode: (key: ReviewStep) => void;
};

const PurchaseDetailsReviewForm = (props: PurchaseDetailsReviewProps) => {
  const { submitReviewForm, handleViewMode } = props;

  const { currentReview, isViewMode } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<PurchaseDetailsReview>(
      currentReview ?? ({} as PurchaseDetailsReview)
    );

  const { errors, validateFields } = useValidation(
    formFields,
    PURCHASE_DETAILS_FORM_FIELDS
  );
  const submitPurchaseDetailsReview = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    submitReviewForm(e, formFields, ReviewStep.PURCHASE_DETAILS, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as PurchaseDetailsReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        {PURCHASE_DETAILS_FORM_FIELDS.filter(
          (s): s is ShortTextQuestion => s.type === QuestionType.SHORT_TEXT
        ).map((question) => (
          <ReviewShortTextQuestion
            key={question.name}
            errors={errors}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) =>
              extendFormFields({ [e.target.name]: e.target.value })
            }
          />
        ))}
        {PURCHASE_DETAILS_FORM_FIELDS.filter(
          (q): q is MultipleChoiceQuestion =>
            q.type === QuestionType.MULTIPLE_CHOICE
        ).map((question) => (
          <MultipleChoiceReviewQuestion
            key={question.name}
            errors={errors}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) =>
              extendFormFields({ [e.target.name]: e.target.value })
            }
            isNarrow={question.options.length > 8}
          />
        ))}
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? handleViewMode(ReviewStep.PURCHASE_DETAILS)
              : submitPurchaseDetailsReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText="Move onto Evaluation"
          onSubmit={(e) => submitPurchaseDetailsReview(e)}
        />
      </form>
    </Container>
  );
};

export default PurchaseDetailsReviewForm;
