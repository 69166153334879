import React, { useContext } from 'react';
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import styled from 'styled-components';
import { UserContext } from '../contexts/UserContext';
import { useResponsive } from '../hooks/useResponsive';

type SideDrawerProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  logOut: () => void;
};

export const Main = MuiStyled('main', {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})<{
  open?: boolean;
  isMobile?: boolean;
}>(({ theme, open, isMobile }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: isMobile ? '0' : '240px',
  }),
}));

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-md);
  justify-content: space-between;
`;

const StyledImage = styled.img`
  height: 80px;
  cursor: pointer;
`;

const drawerWidth = 240;

const SideDrawer = (props: SideDrawerProps) => {
  const { isOpen, setIsOpen, logOut } = props;
  const { user } = useContext(UserContext);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const NAV_MAPPINGS = [
    {
      text: 'Company Search',
      icon: <SearchIcon />,
      onClick: () => navigate('/company-search'),
    },
    {
      text: 'Account Settings',
      icon: <SettingsIcon />,
      onClick: () => navigate('/account-settings'),
    },
    user && user.isAdmin
      ? {
          text: 'Admin View',
          icon: <LeaderboardIcon />,
          onClick: () => navigate('/admin'),
        }
      : null,
    {
      text: user ? 'Logout' : 'Login',
      icon: user ? <LogoutIcon /> : <LoginIcon />,
      onClick: user ? logOut : () => navigate('/sign-in'),
    },
  ];

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isMobile ? 'temporary' : 'persistent'}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DrawerHeader>
          <IconButton onClick={() => navigate('/welcome')}>
            <StyledImage src={require('../../assets/images/kandir_logo.png')} />
          </IconButton>
          <IconButton onClick={() => setIsOpen(false)}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        {user?.firstName && user?.lastName && (
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate('/welcome')}>
              <ListItemText
                primary={'Hi, ' + user?.firstName + ' ' + user?.lastName}
              />
            </ListItemButton>
          </ListItem>
        )}
        <Divider />
        <List>
          {NAV_MAPPINGS.map((navLink, index) =>
            navLink ? (
              <ListItem key={index} disablePadding>
                {!user && navLink.text === 'Account Settings' ? (
                  <span></span>
                ) : (
                  <ListItemButton onClick={navLink.onClick}>
                    <ListItemIcon>{navLink.icon}</ListItemIcon>
                    <ListItemText primary={navLink.text} />
                  </ListItemButton>
                )}
              </ListItem>
            ) : null
          )}
        </List>
      </Drawer>
    </>
  );
};

export default SideDrawer;
