import styled from 'styled-components';
import { screenLgMin, screenMdMin } from '../Breakpoints';

export const CardWrapper = styled.div<{ fullWidth?: boolean }>`
  background-color: var(--color-white);
  border-radius: var(--border-radius);
  margin-bottom: var(--spacing-md);
  width: 100%;
  padding: var(--spacing-md);
  :hover {
    top: calc(50% - 2px);
    border: 1px solid rgba(#f2f4f8, 0);
    box-shadow:
      0 35px 40px rgba(0, 0, 0, 0.05),
      0 0 5px rgba(0, 0, 0, 0.1);
  }

  @media ${screenLgMin} {
    width: ${({ fullWidth }) => (fullWidth ? '100%' : '65%')};
  }
  @media ${screenMdMin} {
    display: flex;
    align-items: center;
    height: 300px;
    :hover {
      top: calc(50% - 2px);
      border: 1px solid rgba(#f2f4f8, 0);
      box-shadow:
        0 15px 20px rgba(0, 0, 0, 0.05),
        0 0 5px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const InnerCardWrapper = styled.div`
  flex-direction: column;
  @media ${screenMdMin} {
    margin-left: var(--spacing-md);
    width: 100%;
  }
`;
