import React from 'react';
import { Modal, Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { StyledActionButton } from './Tables/Table';
import {
  StyledSelectWrapper,
  StyledPublishButton,
} from '../../scenes/review/styles/review.styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type BaseModalProps = {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  title: string;
  handleSubmit: (e: React.MouseEvent<HTMLElement>) => void;
  isSubmitDisabled?: boolean;
  buttonVariant?: 'default' | 'AIValidation';
  nextButtonText?: string;
};

const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled(Paper)`
  position: absolute;
  width: 50%;
  max-width: 600px;
  padding: 20px;
  outline: none;
  background-color: white;
  border-radius: 8px;
`;

const ModalTitle = styled(Typography)`
  margin-bottom: 20px;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: #f0f0f0;
  margin: var(--spacing-md) 0;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BaseModal = (props: BaseModalProps) => {
  const {
    open,
    handleClose,
    children,
    title,
    handleSubmit,
    isSubmitDisabled,
    buttonVariant = 'default',
    nextButtonText,
  } = props;

  const renderButtons = () => {
    if (buttonVariant === 'AIValidation') {
      return (
        <>
          <StyledSelectWrapper>
            <StyledPublishButton onClick={handleClose} variant="outlined">
              Edit my answers
            </StyledPublishButton>
            <StyledPublishButton
              onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
              variant="contained"
              color="primary"
              type="submit"
              endIcon={<ArrowForwardIcon />}
            >
              {nextButtonText}
            </StyledPublishButton>
          </StyledSelectWrapper>
        </>
      );
    }

    return (
      <>
        <StyledActionButton onClick={handleClose} typeVariant="">
          Cancel
        </StyledActionButton>
        <StyledActionButton
          onClick={(e: React.MouseEvent<HTMLElement>) => handleSubmit(e)}
          typeVariant="create"
          type="submit"
          disabled={isSubmitDisabled}
        >
          Submit
        </StyledActionButton>
      </>
    );
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <ModalContent>
        <form>
          <ModalTitle variant="h4" id="modal-title">
            {title}
          </ModalTitle>
          <ModalBody>{children}</ModalBody>
          <Divider />
          <ModalFooter>{renderButtons()}</ModalFooter>
        </form>
      </ModalContent>
    </StyledModal>
  );
};

export default BaseModal;
