import { useState, useContext, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import _ from 'underscore';
import { UserContext } from '../../../../shared/contexts/UserContext';
import {
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
} from '../../../../shared/domain/form';
import { ReviewStep, LegalReview } from '../../../../shared/domain/review';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import { useReviewContext } from '../../context/ReviewContext';
import { LEGAL_FORM_FIELDS } from '../../data';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import useValidation from '../../hooks/useValidation';
import AIValidationModal from '../AIValidationModal';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';
import ReviewFormButtons from '../ReviewFormButtons';
import { Container } from '../../styles/review.styles';

type LegalReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  companyName?: string;
  handleViewMode: (reviewStep: ReviewStep) => void;
  shouldShowAIValidation: boolean;
  updateAiValidationShown: () => void;
};

export const liabilityValueMap: Record<string, number> = {
  '1-3x ARR': 1,
  '4-5x ARR': 2,
  '6-10x ARR': 3,
  '10-15x ARR': 4,
  '16-20x ARR': 5,
  '20x+ ARR': 6,
  Unlimited: 7,
  'N/A': 0,
};

const nextButtonText = 'Move onto Negotiation';

const LegalReviewForm = (props: LegalReviewProps) => {
  const {
    submitReviewForm,
    handleViewMode,
    shouldShowAIValidation,
    updateAiValidationShown,
    companyName,
  } = props;

  const { currentReview, isViewMode } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<LegalReview>(currentReview ?? ({} as LegalReview));

  const [aiValidationResult, setAiValidationResult] = useState<string | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const { errors, validateFields } = useValidation(
    formFields,
    LEGAL_FORM_FIELDS
  );

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    extendFormFields({ ...formFields, [name]: value });
  };

  const submitLegalReview = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    if (shouldShowAIValidation === false) {
      handleCloseAndSubmitModal(e);
      return;
    }

    try {
      const dataToSend = {
        ...formFields,
        companyName: companyName ?? 'new company',
        formName: 'legal-review',
      };
      //Open the modal before making the call to the backend to improve percieved performance
      setIsModalOpen(true);
      setAiValidationResult(null); //so old data is cleared out in event of re-running it

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review/ai-validate`,
        dataToSend,
        {
          headers: {
            Authorization: user?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );

      setAiValidationResult(JSON.stringify(response.data.validationMessage));
      updateAiValidationShown();
    } catch (error) {
      console.error('Error validating form:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const handleCloseAndSubmitModal = (e: React.FormEvent<HTMLFormElement>) => {
    setIsModalOpen(false);
    submitReviewForm(e, formFields, ReviewStep.LEGAL, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as LegalReview));
  }, [currentReview]);

  return (
    <Container>
      <form onSubmit={submitLegalReview}>
        {LEGAL_FORM_FIELDS.filter(
          (q): q is MultipleChoiceQuestion =>
            q.type === QuestionType.MULTIPLE_CHOICE
        )
          .filter((question) => shouldShowQuestion(question, formFields))
          .map((question) => (
            <MultipleChoiceReviewQuestion
              key={question.name}
              question={question}
              formFields={formFields}
              handleFormFieldChange={handleFormFieldChange}
              errors={errors}
              isNarrow={question.options.length > 8}
            />
          ))}

        <Grid container>
          {LEGAL_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isViewMode={false}
                index={i}
              />
            ))}
        </Grid>

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode ? handleViewMode(ReviewStep.LEGAL) : submitLegalReview(e)
          }
          submitButtonText={nextButtonText}
          nextButtonText="Move onto Negotiation"
          onSubmit={(e) => submitReviewForm(e, formFields, ReviewStep.LEGAL)}
        />
      </form>
      <AIValidationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCloseAndSubmitModal}
        validationResult={aiValidationResult}
        nextButtonText={nextButtonText}
      />
    </Container>
  );
};

export default LegalReviewForm;
