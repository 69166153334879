import React, { useState } from 'react';
import { Menu, MenuItem, MenuProps } from '@mui/material';
import styled from 'styled-components';

// Styled reusable menu
const StyledMenu = styled((props: MenuProps) => <Menu {...props} />)`
  & .MuiPaper-root {
    border-radius: 8px;
    min-width: 150px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    padding: 4px;
  }
`;

// Styled reusable menu item
const StyledMenuItem = styled(MenuItem)`
  padding: 8px 12px;
  font-size: 0.875rem;
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  transition:
    background-color 0.2s ease,
    transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5;
    transform: translateX(2px);
  }

  &:active {
    transform: translateX(1px);
  }
`;

type ContextMenuProps = {
  contextMenu: { mouseX: number; mouseY: number } | null;
  handleClose: () => void;
  menuItems: { label: string; action: () => void }[];
};

const ContextMenu: React.FC<ContextMenuProps> = ({
  contextMenu,
  handleClose,
  menuItems,
}) => {
  if (!contextMenu) return null;

  return (
    <StyledMenu
      open={!!contextMenu}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenu
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      {menuItems.map((item, index) => (
        <StyledMenuItem
          key={index}
          onClick={() => {
            item.action();
            handleClose();
          }}
        >
          {item.label}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export default ContextMenu;
