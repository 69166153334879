import { Button, TextField } from '@mui/material';
import styled from 'styled-components';
import { P, screenMdMin } from '../../../shared/styles';

export const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  justify-content: space-between;
  margin: var(--spacing-sm) 0;
  @media ${screenMdMin} {
    flex-direction: unset;
  }
`;

export const StyledPublishButton = styled(Button)`
  text-transform: capitalize;
  border-radius: 24px;
  width: 250px;
  height: 85px;
  font-size: 18px;
  color: var(--color-black);
  background-color: #ccdcf8;
  &:hover {
    background-color: #ccdcf8;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-md);
`;

export const Container = styled.div`
  margin: var(--spacing-md);
  display: flex;
  flex-direction: column;
`;

export const StyledText = styled(P)<{ spaceleft?: boolean }>`
  margin: var(--spacing-sm) 0;
  font-size: 14px;
  @media ${screenMdMin} {
    margin-left: ${({ spaceleft }) => (spaceleft ? 'var(--spacing-md)' : '0')};
  }
`;

export const StyledTextField = styled(TextField)<{ isWide?: boolean }>`
  width: 40%;
  @media ${screenMdMin} {
    margin-top: var(--spacing-xs);
    width: ${({ isWide }) => (isWide ? '50%' : '20%')};
  }
`;
