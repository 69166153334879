import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import ErrorMessage from '../../../../shared/components/ErrorMessage';
import { Textarea } from '../../../../shared/components/TextArea';
import { TextQuestion } from '../../../../shared/domain/form';
import { StyledText } from '../../styles/review.styles';
import { useReviewContext } from '../../context/ReviewContext';

interface ReviewTextAreaProps {
  question: TextQuestion;
  formFields: Record<string, any>;
  handleFormFieldChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  errors: Record<string, string>;
  isViewMode?: boolean;
  includeTextLimitValidation?: boolean;
  index?: number;
}

const StyledTextArea = styled(Textarea)`
  width: 95%;
  resize: none;
`;

const CharacterLimitText = styled.div`
  font-size: 12px;
  color: #888;
  margin-top: 4px;
`;

const CHARACTER_MIN_LIMIT = 50;

const ReviewTextArea: React.FC<ReviewTextAreaProps> = ({
  question,
  formFields,
  handleFormFieldChange,
  errors,
  includeTextLimitValidation,
  index = 0,
}) => {
  const [remainingCharacters, setRemainingCharacters] = useState(
    Math.max(CHARACTER_MIN_LIMIT - (formFields[question.name]?.length || 0), 0)
  );

  const { isViewMode } = useReviewContext();

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    setRemainingCharacters(Math.max(CHARACTER_MIN_LIMIT - text.length, 0));
    handleFormFieldChange(event);
  };

  const isBelowMinimum =
    (formFields[question.name]?.length || 0) < CHARACTER_MIN_LIMIT;

  return (
    <Grid item sm={6} xs={12} key={question.name}>
      <motion.div
        {...(question.conditionallyShow && !isViewMode
          ? {
              initial: {
                x: '-100%',
                opacity: 0,
              },
              transition: {
                type: 'spring',
                stiffness: 100,
                damping: 15,
                delay: index * 0.2,
              },
            }
          : {})}
        animate={{
          x: 0,
          opacity: 1,
        }}
      >
        <StyledText>{question.question}</StyledText>
        <StyledTextArea
          readOnly={isViewMode}
          onChange={handleTextChange}
          name={question.name}
          value={formFields[question.name] || ''}
          minRows={4}
          required={question.required}
        />
        {includeTextLimitValidation && (
          <CharacterLimitText>
            {isBelowMinimum
              ? `${remainingCharacters} more characters needed`
              : `Minimum character limit met`}
          </CharacterLimitText>
        )}

        {errors[question.name] && (
          <ErrorMessage error={errors[question.name]} />
        )}
      </motion.div>
    </Grid>
  );
};

export default ReviewTextArea;
