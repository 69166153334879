import { useState } from 'react';
import _ from 'lodash';
import { Question, QuestionValidationRules } from '../../../shared/domain/form';
import { CurrentReview } from '../../../shared/domain/review';
import { shouldShowQuestion } from '../helpers/ReviewFormHelpers';

type ValidationErrors = Record<string, string>;

const MINIMUM_CHARACTERS = 50;

const useValidation = (
  formFields: Record<string, any>,
  questions: Question[]
) => {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validateFields = () => {
    const validationErrors: ValidationErrors = {};

    questions.forEach((question) => {
      if (!shouldShowQuestion(question, formFields as CurrentReview)) {
        return;
      }

      const value = formFields[question.name];

      if (
        question.validationRules?.includes(QuestionValidationRules.REQUIRED) &&
        (value === undefined ||
          value === null ||
          (typeof value === 'string' && value.trim() === '') ||
          (typeof value === 'number' && value <= 0) ||
          (Array.isArray(value) && value.length === 0))
      ) {
        validationErrors[question.name] = 'This field is required';
      }
      if (
        question.validationRules?.includes(
          QuestionValidationRules.MINIMUM_CHARACTERS
        ) &&
        typeof value === 'string' &&
        value.trim().length < MINIMUM_CHARACTERS
      ) {
        validationErrors[question.name] =
          `This field must have at least ${MINIMUM_CHARACTERS} characters.`;
      }
    });

    setErrors(validationErrors);
    return !_.isEmpty(validationErrors);
  };

  return { errors, validateFields };
};

export default useValidation;
