import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useReviewContext } from '../context/ReviewContext';

type ReviewDialogHeaderProps = {
  companyName: string;
  handleClose: () => void;
  isDeepDive?: boolean;
};

const ReviewDialogHeader = (props: ReviewDialogHeaderProps) => {
  const { companyName, handleClose, isDeepDive } = props;

  const { isViewMode } = useReviewContext();

  return (
    <AppBar sx={{ position: 'relative' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        {!isViewMode ? (
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Leave a {isDeepDive ? <strong>Deep Dive</strong> : ''} review for{' '}
            {companyName}
          </Typography>
        ) : (
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Review for {companyName}
          </Typography>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ReviewDialogHeader;
