import { Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'underscore';
import {
  NegotiationReview,
  ReviewStep,
} from '../../../../shared/domain/review';
import { Container } from '../../styles/review.styles';
import ReviewFormButtons from '../ReviewFormButtons';
import { NEGOTIATION_FORM_FIELDS } from '../../data';
import { UserContext } from '../../../../shared/contexts/UserContext';
import AIValidationModal from '../AIValidationModal';
import {
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
} from '../../../../shared/domain/form';
import { useReviewContext } from '../../context/ReviewContext';
import useValidation from '../../hooks/useValidation';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';

type NegotiationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  rfp: boolean;
  setOpen?: (isOpen: boolean) => void;
  handleViewMode: (key: ReviewStep) => void;
  companyName?: string;
  shouldShowAIValidation: boolean;
  updateAiValidationShown: () => void;
};

const NegotiationReviewForm = (props: NegotiationReviewProps) => {
  const {
    submitReviewForm,
    rfp,
    setOpen,
    handleViewMode,
    companyName,
    shouldShowAIValidation,
    updateAiValidationShown,
  } = props;

  const { currentReview, isViewMode } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<NegotiationReview>(
      currentReview ?? ({} as NegotiationReview)
    );

  const { errors, validateFields } = useValidation(
    formFields,
    NEGOTIATION_FORM_FIELDS
  );

  const [aiValidationResult, setAiValidationResult] = useState<string | null>(
    null
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    extendFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const submitNegotiationReview = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    if (shouldShowAIValidation === false) {
      handleCloseAndSubmitModal(e);
      return;
    }

    try {
      const dataToSend = {
        ...formFields,
        companyName: companyName ?? 'new company',
        formName: 'negotiation-review',
      };

      // Open the modal before making the call to the backend to improve perceived performance
      setIsModalOpen(true);
      setAiValidationResult(null); // clear old data for re-runs

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review/ai-validate`,
        dataToSend,
        {
          headers: {
            Authorization: user?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );

      setAiValidationResult(JSON.stringify(response.data.validationMessage));
      updateAiValidationShown();
    } catch (error) {
      console.error('Error validating form:', error);
    }
  };

  const handleCloseAndSubmitModal = (e: React.FormEvent<HTMLFormElement>) => {
    setIsModalOpen(false);
    submitReviewForm(e, formFields, ReviewStep.NEGOTIATION, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as NegotiationReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        <Grid container>
          {NEGOTIATION_FORM_FIELDS.filter(
            (q): q is MultipleChoiceQuestion =>
              q.type === QuestionType.MULTIPLE_CHOICE
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question) => (
              <MultipleChoiceReviewQuestion
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isNarrow={question.options.length > 8}
              />
            ))}

          {NEGOTIATION_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                key={question.name}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isViewMode={false}
                index={i}
              />
            ))}
        </Grid>

        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? handleViewMode(ReviewStep.NEGOTIATION)
              : submitNegotiationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={rfp ? 'Move onto RFP' : 'Submit Deep Dive Review'}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? setOpen && setOpen(false)
              : submitReviewForm(e, formFields, ReviewStep.NEGOTIATION)
          }
        />
      </form>
      <AIValidationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleCloseAndSubmitModal}
        validationResult={aiValidationResult}
        nextButtonText={rfp ? 'Move onto RFP' : 'Submit Deep Dive Review'}
      />
    </Container>
  );
};

export default NegotiationReviewForm;
