import React, { useState } from 'react';
import BaseModal from '../../../shared/components/BaseModal';
import { P } from '../../../shared/styles';
import { TextField } from '@mui/material';

type InviteNewUserModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  inviteUser: (
    e: React.MouseEvent<HTMLElement>,
    email: string,
    name?: string
  ) => void;
};

const InviteNewUserModal = (props: InviteNewUserModalProps) => {
  const { isOpen, handleClose, inviteUser } = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  return (
    <BaseModal
      open={isOpen}
      handleClose={handleClose}
      title="Invite New User"
      handleSubmit={(e) => inviteUser(e, email, name)}
    >
      <P>
        Enter a the email address of the person you would like to invite to
        Kandir.
      </P>
      <TextField
        required
        fullWidth
        label="Email"
        variant="outlined"
        margin="normal"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        label="Recipient Name"
        variant="outlined"
        margin="normal"
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </BaseModal>
  );
};

export default InviteNewUserModal;
