import React, { useContext, useState } from 'react';
import BaseModal from '../../../shared/components/BaseModal';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { FormControlLabel, Checkbox } from '@mui/material';
import { P } from '../../../shared/styles';
import styled from 'styled-components';
import axios from 'axios';
import { UserContext } from '../../../shared/contexts/UserContext';

interface AIValidationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  validationResult: string | null;
  nextButtonText: string;
}

interface Question {
  number: string;
  question: string;
}

const StyledOrderedList = styled.ol`
  font-weight: normal;
  font-family: roboto;
  font-size: var(--font-size-p);
  line-height: var(--line-height-p);
  margin: 10px;
  max-width: var(--max-line-length);
`;

const StyledListItem = styled.li`
  margin-bottom: var(--spacing-xs);
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  color: var(--color-text-primary);
`;

const renderLLMResponse = (LLMResponse: string) => {
  const responseJSON = JSON.parse(LLMResponse);
  const summary = responseJSON.summary;
  const questions = responseJSON.questions;
  return (
    <div>
      <P>{summary}</P>
      <StyledOrderedList>
        {questions.map((question: Question) => (
          <StyledListItem key={question.number}>
            {question.question}
          </StyledListItem>
        ))}
      </StyledOrderedList>
    </div>
  );
};

const AIValidationModal = (props: AIValidationModalProps) => {
  const { isOpen, onClose, onSubmit, validationResult, nextButtonText } = props;
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { user } = useContext(UserContext);

  const handleSubmit = () => {
    // Create a synthetic event that mimics a form submission event
    const syntheticFormEvent = {
      preventDefault: () => {},
      target: {
        // Add any other properties that might be needed by submitReviewForm
      },
    } as React.FormEvent<HTMLFormElement>;

    onClose();
    onSubmit(syntheticFormEvent);
  };

  const handleDontShowAgain = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDontShowAgain(event.target.checked);

    const dataToSend = {
      preferences: {
        showAIValidation: dontShowAgain,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/user/${user?.id}/updatePreferences`,
        dataToSend,
        {
          headers: {
            Authorization: user?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error Updating User Preference:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <BaseModal
      open={isOpen}
      handleClose={onClose}
      title="Before you move on!"
      handleSubmit={handleSubmit}
      buttonVariant="AIValidation"
      nextButtonText={nextButtonText}
    >
      {!validationResult && <LoadingProgress />}
      {validationResult && renderLLMResponse(validationResult)}
      <FormControlLabel
        control={
          <Checkbox
            checked={dontShowAgain}
            onChange={handleDontShowAgain}
            name="dontShowAgain"
          />
        }
        label="Don't show this again"
      />
    </BaseModal>
  );
};
export default AIValidationModal;
